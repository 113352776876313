<template>
    <div v-if="data.content.isTocEnabled" class="toc">
        <h2 v-if="data.content.title">{{ data.content.title }}</h2>

        <TocTree :toc="toc" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getter } from '@/modules/platform/store/const';
import { getObjectField } from '@/modules/core/utils/helper';
import TocTree from './TocTree.vue';

export default {
    name: 'TocComponent',
    components: { TocTree },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters({
            getPages: getter.GET_PAGES,
        }),
        toc() {
            const pageName = getObjectField(this.$route, 'params.pathMatch', '').split('/').pop();

            return getObjectField(this.getPages[pageName], 'tocTree', []);
        },
    },
};
</script>
<style lang="scss" scoped>
.toc {
    margin: 20px 0;
}
</style>
