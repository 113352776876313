<template>
    <div class="splash-page" :style="{ backgroundImage: `url(${splashPageConfiguration.backgroundImage})` }">
        <section class="header">
            <div class="header-item logo">
                <ImageIcon :src="splashPageConfiguration.logo" />
            </div>

            <div class="header-item"><LanguageSwitcher @languageChanged="onLanguageChanged" /></div>
        </section>

        <div class="splash-page-content">
            <div class="signup-wrap">
                <form v-if="!userPreRegistered" @submit.prevent="preRegister">
                    <h4 class="title">{{ splashPageConfiguration.formTitle }}</h4>
                    <div class="description">{{ splashPageConfiguration.formDescription }}</div>
                    <PhoneNumber
                        form-name="pre-register-form"
                        :v="$v.phoneNumber"
                        :value="phoneNumber"
                        :error-messages="{ phoneNumberFormat: $t('splashPage.invalidPhoneError') }"
                        @value="phoneNumber = $event"
                    />

                    <p v-if="registrationError" class="notify error">{{ registrationError }}</p>

                    <input
                        type="submit"
                        class="button button-submit button-full"
                        :aria-placeholder="$t('splashPage.submitBtnText')"
                        :value="$t('splashPage.submitBtnText')"
                        :disabled="isSubmitBtnDisabled"
                    />

                    <div class="change-country-link">
                        <a href="https://www.betpawa.com/return">
                            {{ $t('splashPage.changeCountryText') }}
                        </a>
                    </div>
                </form>
                <p v-else class="notify success">{{ $t('splashPage.submitSuccess') }}</p>
            </div>
        </div>

        <footer>
            <div v-if="footerContent">
                <DynamicComponent v-for="(item, index) in footerContent.content" :key="'copyright-' + index" :data="item" path="content" />
            </div>
        </footer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { action as platformAction, getter as platformGetter } from '@/modules/platform/store/const';
import { action as authAction } from '@/modules/platform/store/modules/auth/const';
import { DynamicComponent } from '@/modules/platform/components';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import LanguageSwitcher from '@/components/Fragments/SplashPage/LanguageSwitcher.vue';
import PhoneNumber from '@/components/PhoneNumber.vue';
import { required } from 'vuelidate/lib/validators';
import { deviceType } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';

const preRegistrationErrors = {
    BAD_REQUEST: 'invalidNumber',
    DEFAULT: 'networkError',
};

const FOOTER_SLOT = 'SPLASH_PAGE_FOOTER';

export default {
    name: 'SplashPage',
    components: { PhoneNumber, LanguageSwitcher, DynamicComponent },
    mixins: [PageMixin, SEOMixin],
    data() {
        return {
            phoneNumber: '',
            isPresto: deviceType.isPresto(),
            userPreRegistered: false,
            registrationError: '',
            seoMeta: {
                template: 'splashPage',
            },
        };
    },
    computed: {
        ...mapGetters({
            contentSlots: platformGetter.GET_CONTENT_SLOTS,
            splashPageConfiguration: platformGetter.GET_SPLASH_PAGE_CONFIGURATION,
            country: platformGetter.GET_COUNTRY,
            isLoading: coreGetter.IS_LOADING,
        }),
        isSubmitBtnDisabled() {
            return !this.isPresto && (this.$v.phoneNumber.$invalid || this.isLoading(authAction.PRE_REGISTER));
        },
        footerContent() {
            return this.contentSlots[FOOTER_SLOT];
        },
    },
    created() {
        this.$nextTick(() => {
            this.setSEOTags({
                meta: {
                    seo: {
                        template: 'splashPage',
                    },
                },
            });
        });

        this.$store.dispatch(platformAction.GET_CONTENT, [FOOTER_SLOT]);
    },
    validations() {
        return {
            phoneNumber: {
                required,
                phoneNumberFormat: (phone) => /^\d{8,11}$/.test(phone),
            },
        };
    },
    methods: {
        onLanguageChanged(language) {
            this.$store.dispatch(platformAction.CHANGE_SITE_LANGUAGE, { language, component: this.$options.name });
        },
        preRegister() {
            this.registrationError = '';

            this.$store
                .dispatch(authAction.PRE_REGISTER, { phoneNumber: this.phoneNumber })
                .then(() => {
                    this.$gtm.query({
                        event: 'pre-launch-sign-up',
                        PhoneNumber: this.phoneNumber,
                        Jurisdiction: this.country,
                    });
                    this.userPreRegistered = true;
                })
                .catch((error) => {
                    const errorMsgMap = {
                        [preRegistrationErrors.BAD_REQUEST]: this.$t('splashPage.invalidPhoneError'),
                        [preRegistrationErrors.DEFAULT]: this.$t('critical.error.appSomethingWentWrong'),
                    };
                    this.registrationError = errorMsgMap[error.errorCode] || errorMsgMap[preRegistrationErrors.DEFAULT];
                })
                .finally(() => {
                    if (this.userPreRegistered) {
                        this.phoneNumber = '';
                        this.$v.phoneNumber.$reset();
                    }
                });
        },
    },
};
</script>

<style scoped lang="scss">
.splash-page {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;

    .header {
        padding: 32px 40px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        &-item {
            margin: 8px 0;
        }
    }

    .logo img {
        width: 112px;
        height: auto;
    }

    &-content {
        display: flex;
        padding: 0 12px;
        flex-grow: 1;
    }

    .signup-wrap {
        max-width: 368px;
        padding: 40px 24px;
        margin: auto;
        z-index: 10;
        background: rgba(255, 255, 255, 0.98);
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.18);

        .title {
            @extend %h2-font-700;
        }

        .description {
            @extend %body-normal-font-400;
            margin-bottom: 20px;
        }

        .change-country-link {
            margin-top: 8px;
            padding: 10px 0;
            text-align: center;
            text-transform: capitalize;
            text-decoration: underline;
            @extend %body-normal-font-400;
        }

        .error {
            margin-bottom: 16px;
        }
    }

    footer {
        color: $dark-grey-2;
        text-align: center;
        margin-top: 32px;
        padding: 24px 8px;
        p {
            @extend %body-normal-font-400;
        }
    }

    @include maxbasic {
        .signup-wrap {
            padding: 24px 16px;
        }
    }

    @include maxoldschoolandmini {
        .signup-wrap {
            padding: 24px 12px;
        }
    }

    @include maxsmtablet {
        .header {
            padding: 16px 18px;
        }
    }
}
</style>
