<template>
    <div class="landing-page">
        <section class="intro" />
        <div class="gallery">
            <h3>{{ $t('ui.landingPage.selectYourCountry') }}</h3>
            <div class="container">
                <a v-for="jurisdiction in activeJurisdictions" :key="jurisdiction.id" :href="jurisdiction.url" class="thumbnail">
                    <CountryFlag :iso-code="jurisdiction.countryCode" is-squared class="flag-icon" />
                    <p class="country-name">{{ jurisdiction.countryName }}</p>
                </a>
            </div>
        </div>
        <footer>
            <img src="@/assets/images/logo_monochrome.png" alt="logo" />
            <div v-if="footerContent">
                <DynamicComponent v-for="(item, index) in footerContent.content" :key="'copyright-' + index" :data="item" path="content" />
            </div>
        </footer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { action, getter as platformGetter } from '@/modules/platform/store/const';
import { DynamicComponent } from '@/modules/platform/components';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import CountryFlag from '@/modules/platform/components/CountryFlag.vue';

const FOOTER_SLOT = 'LANDING_PAGE_FOOTER';

export default {
    name: 'LandingPage',
    components: { CountryFlag, DynamicComponent },
    mixins: [PageMixin, SEOMixin],
    computed: {
        ...mapGetters({
            contentSlots: platformGetter.GET_CONTENT_SLOTS,
            activeJurisdictions: platformGetter.GET_ACTIVE_JURISDICTIONS,
        }),
        footerContent() {
            return this.contentSlots[FOOTER_SLOT];
        },
    },
    created() {
        this.$nextTick(() => {
            this.setSEOTags({
                meta: {
                    seo: {
                        template: 'landing',
                    },
                },
            });
        });
        this.$store.dispatch(action.GET_ALL_ACTIVE_JURISDICTIONS);
        this.$store.dispatch(action.GET_CONTENT, [FOOTER_SLOT]);
    },
};
</script>

<style scoped lang="scss">
.landing-page {
    background-color: $light-grey;
    overflow-y: auto;
    height: 100%;

    .flag-icon {
        width: 60px;
        height: 60px;
        border-radius: 100%;
    }

    .intro {
        background-color: #575755;
        background-image: url(../../assets/images/backgrounds/my-betpawa.jpg);
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        min-height: 20%;

        @media (max-width: 796px) and (min-width: 320px) {
            min-height: 32%;
        }

        @media (min-width: 796px) {
            min-height: 50%;
        }
    }

    .gallery {
        flex: 1;

        h3 {
            @extend %h3-font-700;
            text-align: center;
            margin: 10px 0 0 0;
            color: #636363;
        }

        .country-name {
            @extend %body-big-font-700;
            text-align: center;
            margin: 10px 0 0 0;
        }

        .container {
            height: 100%;
            width: 100%;
            margin: 0 auto;
            max-width: 1480px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .thumbnail {
                width: 23%;
                text-align: center;
                margin-top: 15px;
                background-color: #fff;
                padding: 15px 0 12px;
                margin-left: 1%;
                margin-right: 1%;

                @media (max-width: 796px) and (min-width: 320px) {
                    width: 46%;
                    margin-left: 2%;
                    margin-right: 2%;
                }

                @media (min-width: 796px) {
                    width: 23%;
                    margin-left: 1%;
                    margin-right: 1%;
                }

                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 100%;
                }
            }
        }
    }

    footer {
        padding: 20px 0 15px;
        text-align: center;
        color: $dark-grey-2;
        font-size: 13px;
        margin: 0 10px;

        img {
            width: 100%;
            max-width: 90px;
        }
    }
}
</style>
