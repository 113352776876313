<template>
    <div
        :class="{
            tabs: true,
            [tabType]: true,
            scrollable,
            dark: isDark,
            'with-bottom-border': bottomBorder,
            'with-top-border': topBorder,
            'have-scroll': tabsHaveScroll,
            'end-of-scroll': endOfScroll,
            'truncate-text': truncateText,
        }"
    >
        <div v-if="isPresto && usePrestoTabs" class="tabs-menu-presto" @click="togglePrestoMenu">
            <span>
                <slot name="tab-prefix" :tab="activePrestoMenuItem" />
                {{ activePrestoMenuItem.text }}
                <slot name="tab-suffix" :tab="activePrestoMenuItem" />
            </span>
            <SvgIcon
                icon-id="arrow_down"
                :style="{
                    transform: `rotate(${!showPrestoMenu ? '0' : '180'}deg)`,
                }"
                class="icon icon-size-very-small"
            />
        </div>
        <ul
            ref="tabsMenu"
            :class="{
                'tabs-menu': true,
                'presto-mode': isPresto && usePrestoTabs,
                'presto-tabs-visible': showPrestoMenu,
                ['align-' + align]: true,
            }"
        >
            <li
                v-for="(tab, index) in tabs"
                :key="tab.key + index"
                :ref="`tab-${tab.key}`"
                :class="{
                    active: tab.key === active.key && !tab.disableHighlight,
                    first: index === 0,
                    last: index === tabs.length - 1,
                    ['no-grow']: (!tab.text || tab.isTextHidden) && !tab.disableNoGrow,
                    highlight: tab.highlight,
                    [tab.sticky]: tab.sticky,
                }"
                :data-test-id="`tabs-${tab.key}`"
                class="tabs-selector"
                @click="handleTabClick(tab)"
            >
                <div class="tab-item">
                    <div class="tab-item-border">
                        <template v-if="tab.componentName">
                            <component :is="tab.componentName" v-bind="tab.props" />
                        </template>
                        <template v-else>
                            <div v-if="tab.iconId" class="tab-icon">
                                <div :class="['om-icon-wrapper', tab.iconClass]">
                                    <SvgIcon class="icon" :class="tab.iconClass" :icon-id="tab.iconId" />
                                </div>
                            </div>
                            <slot name="tab-prefix" :tab="tab" />
                            <span v-if="tab.text && !tab.isTextHidden" class="tab-text">{{ tab.text }}</span>
                            <span v-if="tab.count" :class="{ 'tab-counter': true, active: tab.key === active.key }">
                                {{ tab.count }}
                            </span>
                            <slot name="tab-suffix" :tab="tab" />
                        </template>
                    </div>
                </div>
            </li>
        </ul>
        <slot />
    </div>
</template>

<script>
import { deviceType, helper } from '@/modules/core';

const TAB_TYPES = ['square', 'round', 'text'];

export default {
    name: 'Tabs',
    props: {
        tabs: {
            type: Array[Object],
            required: true,
        },
        active: {
            type: Object,
            default() {
                return {};
            },
        },
        tabType: {
            type: String,
            default: 'square',
            validator: (type) => TAB_TYPES.includes(type),
        },
        align: {
            type: String,
            default: 'center',
        },
        scrollable: Boolean,
        bottomBorder: Boolean,
        topBorder: {
            type: Boolean,
            default: true,
        },
        usePrestoTabs: Boolean,
        tabChangeTrackingName: String,
        truncateText: Boolean,
        isDark: Boolean,
    },
    data() {
        return {
            tabsHaveScroll: false,
            endOfScroll: false,
            isPresto: deviceType.isPresto(),
            showPrestoMenu: false,
        };
    },
    computed: {
        activePrestoMenuItem() {
            const activeItem = this.tabs.filter((item) => item.key === this.active.key);

            return activeItem[0];
        },
    },
    watch: {
        active() {
            this.setScrollPosition();
        },
    },
    mounted() {
        if (this.scrollable) {
            this.setTabsScrollState();
            this.setScrollPosition();
            window.addEventListener('resize', this.setTabsScrollState);
            this.$refs.tabsMenu.addEventListener('scroll', this.setCheckEndOfScroll);
        }
    },
    beforeDestroy() {
        if (this.scrollable) {
            window.removeEventListener('resize', this.setTabsScrollState);
            this.$refs.tabsMenu.removeEventListener('scroll', this.setCheckEndOfScroll);
        }
    },
    methods: {
        setTabsScrollState() {
            const tabsMenu = this.$refs.tabsMenu;
            this.tabsHaveScroll = tabsMenu.scrollWidth > tabsMenu.clientWidth;
            this.$emit('tabsHaveScroll', this.tabsHaveScroll);
        },
        setCheckEndOfScroll() {
            const tabsMenu = this.$refs.tabsMenu;
            const scrollWidth = tabsMenu.scrollWidth;
            const clientWidth = tabsMenu.clientWidth;
            const scrollLeft = tabsMenu.scrollLeft;
            const tolerance = 1;
            this.endOfScroll = clientWidth + scrollLeft >= scrollWidth - tolerance;
        },
        togglePrestoMenu() {
            if (this.usePrestoTabs) {
                this.showPrestoMenu = !this.showPrestoMenu;
            }
        },
        handleTabClick(tab) {
            this.$emit('select', tab);

            if (this.tabChangeTrackingName) {
                this.$gtm.query({
                    event: this.$gtm.makeValidEventName(this.tabChangeTrackingName + tab.key),
                });
            }

            const metrika = helper.getObjectField(tab, 'data.metrika');
            if (metrika) {
                this.$gtm.query({
                    event: 'tab_click',
                    metrika,
                });
            }

            if (this.isPresto) {
                this.showPrestoMenu = false;
            }
        },
        setScrollPosition() {
            if (!this.tabsHaveScroll) {
                return;
            }

            this.$nextTick(() => {
                const [tab] = this.$refs[`tab-${this.active.key}`] ?? [];

                if (tab) {
                    tab.scrollIntoView({ block: 'nearest', inline: 'center' });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.tabs {
    .tabs-menu {
        display: flex;
        list-style: none;
        user-select: none;

        &.presto-mode {
            display: none;

            &.presto-tabs-visible {
                display: block;
            }

            .tabs-selector {
                border-bottom: 1px solid $border-color;

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }

        .tabs-selector {
            &.no-grow {
                width: auto;
                padding-left: 12px;
                padding-right: 12px;
            }
        }
    }

    .tabs-menu-presto {
        border-bottom: 1px solid $border-color;
        padding: 4px 0 4px 16px;
        font-size: 18px;
        font-weight: bold;

        svg {
            float: right;
            margin: 7px 7px 0;
        }
    }

    .tabs-selector {
        width: 100%;
        cursor: pointer;

        .tab {
            &-counter {
                margin-left: 4px;
                width: 17px;
                height: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: $medium-grey;
                @extend %caption-font-400;

                &.active {
                    background: $message-success;
                }
            }
            &-item {
                height: 100%;

                &-border {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;

                    @include maxbasic {
                        @include only_mini {
                            flex-wrap: wrap;
                            text-align: center;
                        }
                    }

                    .tab-icon {
                        display: flex;
                        align-items: center;

                        & + .tab-text {
                            margin-left: 8px;
                        }

                        .om-icon-wrapper {
                            @include only_mini {
                                position: relative;
                                top: -2px;
                            }

                            @include all_but_mini {
                                display: flex;
                            }

                            @extend %icon-sizes;
                        }
                    }
                }
            }
        }

        &.sticky-left,
        &.sticky-right {
            position: sticky;
        }
        &.sticky-left {
            left: 0;
        }
        &.sticky-right {
            right: 0;
        }
    }

    &.square {
        .tabs-selector {
            @extend %body-normal-font-400;
            padding: 10px 16px;
            background: $light-grey-bg;
            border: 1px solid $border-color;
            border-top: none;

            &:not(.last) {
                border-right: none;
            }

            @include only_mini {
                padding: 10px 2px;
            }

            &.active {
                background: $white-bg;
                border-bottom: none;

                .tab-text {
                    @extend %body-normal-font-700;
                }

                &.first {
                    border-left: none;
                }

                &.last {
                    border-right: none;
                }
            }
        }
    }

    &.round {
        .tabs-selector {
            .tab-item {
                border-radius: 200px;
                border: 1px solid $casino-pill-border;
                background: white;
                color: $casino-pill-text-color;
                height: 34px;

                &-border {
                    .tab-icon + .tab-text {
                        margin-left: 4px;
                    }
                    background: white;
                    padding: 6px 16px;
                    border: 1px solid transparent;
                    border-radius: 200px;
                    width: 100%;
                    height: 100%;
                }
            }

            margin-right: 8px;
            flex: 0;

            .tab-text {
                @extend %small-details-font-400;
            }

            &.last {
                .tab-item {
                    margin-right: 0;
                }
            }

            &.active {
                .tab-item {
                    background-color: #ddfaa5;
                    border-color: #9ce800;
                    border-width: 1px;
                    &-border {
                        background-color: #ddfaa5;
                        border-color: #9ce800;
                        border-width: 1px;
                    }
                }
            }
        }
    }

    &.text {
        .tabs-menu {
            padding: 3px 0;
            justify-content: center;

            &.align-left {
                justify-content: flex-start;
            }

            &.align-right {
                justify-content: flex-end;
            }
        }

        .tabs-selector {
            width: auto;
            padding: 11px 12px;
            background: none;
            border: none;

            .tab-text {
                @extend %body-normal-font-700;
            }

            &.active .tab-text {
                @extend %body-normal-font-700;
                color: $tabs-selector-color;
            }
        }

        &:not(.scrollable) {
            .tabs-menu {
                flex-wrap: wrap;
            }
        }
    }

    &.with-bottom-border {
        .tabs-menu {
            border-bottom: 1px solid $border-color;
        }
    }

    &.with-top-border.square {
        > .tabs-menu > .tabs-selector {
            border-top: 1px solid $border-color;
        }
    }

    &.scrollable {
        .tabs-menu {
            overflow: scroll;

            @include hide_scrollbar;
        }

        .tabs-selector {
            white-space: nowrap;
        }

        &.have-scroll .tabs-menu {
            justify-content: unset;
        }
    }

    &.truncate-text.square {
        @include all_but_mini {
            .tab-item,
            .tabs-selector {
                overflow: hidden;

                .tab-text {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        @mixin presto-width($sizes) {
            @each $size in $sizes {
                @media screen and (max-width: $size) {
                    max-width: calc(calc(#{$size}/ 2) - 8px); // minus padding
                }
            }
        }

        @include only_mini_feature_phone {
            $sizes: 280px, 270px, 260px, 250px, 240px, 230px, 220px, 210px, 200px;
            .tabs-selector {
                @include presto-width($sizes);
                overflow: hidden;
            }
        }
    }

    &.dark {
        background: $navigation-menu-background-color-dark;
        .tabs-menu {
            background: transparent;
            padding: 0;
            border: 0;
            display: flex;

            .tabs-selector {
                background: transparent;
                color: $navigation-menu-color-dark;
                border: none;
                flex-grow: 1;
                border-bottom: 2px solid transparent;

                .tab-item .tab-text {
                    @extend %body-small-font-700;
                    text-transform: uppercase;
                }

                &.active {
                    color: $light-green;
                    border-bottom: 2px solid $light-green;
                    background: none;

                    .tab-item .tab-icon .svg-icon {
                        fill: $light-green;
                    }
                }

                &.highlight {
                    color: $light-green;

                    .tab-item .tab-icon .svg-icon {
                        fill: $light-green;
                    }
                }

                .tab-item {
                    .tab-icon .svg-icon {
                        fill: map-get($bg-neutral, 'bg-lighten-2');
                    }
                }

                &.sticky-left,
                &.sticky-right {
                    background: $navigation-menu-background-color-dark;
                }
                &.sticky-right {
                    right: 0;
                }
            }

            &.presto-mode {
                display: none;
                border: none;
                color: map-get($bg-neutral, 'bg-lighten-2');

                .tabs-selector {
                    padding-top: 9px;
                    padding-bottom: 9px;
                    border-bottom: 1px solid #44494c;

                    &:last-of-type {
                        border-bottom: none;
                    }

                    @include only_mini {
                        .tab-item-border {
                            justify-content: center;
                        }
                    }
                }

                &.presto-tabs-visible {
                    display: block;
                }
            }
        }
        .tabs-menu-presto {
            color: $light-green;
            border: 1px solid #44494c;

            svg {
                fill: $light-green;
            }
        }
        &.round {
            background: $dark-menu-category-color;

            .tabs-menu {
                .tabs-selector {
                    .tab-item {
                        padding: 15px;
                        height: inherit;
                        background-color: $dark-menu-background-color;
                        border: 1px solid $dark-menu-item-border;

                        .tab-text {
                            @extend %body-normal-font-400;
                            text-transform: inherit;
                        }

                        .tab-item-border {
                            color: $dark-grey-2;
                            background: inherit;
                            border: none;
                            padding: 0;
                        }
                    }

                    &.active {
                        color: $green;
                        border-bottom: none;
                        .tab-item {
                            background-color: $dark-menu-category-selected-background;

                            .tab-item-border {
                                color: $green;
                            }
                        }
                    }
                }
            }
        }
        &.have-scroll {
            .tabs-selector.sticky-right {
                border-left: $dark-green solid 1px;
                @include all_but_mini {
                    &:before {
                        content: '';
                        position: absolute;
                        width: 48px;
                        height: calc(100% + 2px); // bottom border
                        left: -48px; // width + border
                        top: 0;
                        background: linear-gradient(90deg, $navigation-menu-shadow 0%, $navigation-menu-background-color-dark 100%);
                        opacity: 1;
                        transition: opacity 0.1s linear;
                        pointer-events: none;
                    }
                }
            }
            @include all_but_mini {
                &.end-of-scroll .tabs-selector.sticky-right:before {
                    opacity: 0;
                }
            }
        }
    }
}
</style>
