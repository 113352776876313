<template>
    <div v-if="isNeedToAddInDOM" :class="{ 'payment-component-wrapper': !isProvidersLoading && providersList && providersList.length }">
        <Spinner :visible="isProvidersLoading" class="inset" />
        <div v-show="showComponent">
            <div v-if="(notOperational || isProviderUnavailable) && !isSelectVisible" class="method-notify notify warning">
                <renderer
                    v-if="isProviderUnavailable"
                    :input="$t('ui.payment.deposit.notification.notAvailable', { provider: selectedProvider.text })"
                />
                <renderer
                    v-else
                    :input="$t(`ui.payment.deposit.notification.${countryCodeIs.NG ? 'notOperationalNigeria' : 'notOperational'}`)"
                />
            </div>
            <div :class="{ 'payment-component payment-component-with-select': isSelectVisible && isBgVisible }">
                <div :class="{ 'payment-component payment-component-specific': !isSelectVisible && isBgVisible }">
                    <UserPhoneOperator
                        v-if="isAuthenticated && !isSelectVisible"
                        :provider-icon="selectedProvider.mediaUrl"
                        :class="{ 'user-operator': isBgVisible }"
                    />
                    <PaystackDeposit
                        v-if="showPaystack"
                        :show-select="isSelectVisible"
                        :button-text="data.buttonText"
                        :input-label="data.inputLabel"
                        :is-button-always-active="data.isButtonAlwaysActive"
                        @closeClick="onCloseClick"
                    />
                    <Mtnnigeriaairtime
                        v-else-if="showMtnNigeriaAirtime"
                        :show-select="isSelectVisible"
                        :button-text="data.buttonText"
                        :input-label="data.inputLabel"
                        :is-button-always-active="data.isButtonAlwaysActive"
                        @closeClick="onCloseClick"
                    />
                    <GeneralPaymentcomponent
                        v-else
                        :show-select="isSelectVisible"
                        :button-text="data.buttonText"
                        :input-label="data.inputLabel"
                        :is-button-always-active="data.isButtonAlwaysActive"
                        @closeClick="onCloseClick"
                    />
                </div>
                <div v-if="showMethodInfo" class="method-info">
                    <div v-for="(description, index) in selectedProvider.description" :key="index" class="method-info-note">
                        <span class="bold">{{ description.label }}</span>
                        &nbsp;
                        <span>{{ description.text }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { Spinner } from '@/modules/core/components';
import { GeneralPaymentcomponent, Mtnnigeriaairtime, PaystackDeposit, UserPhoneOperator } from '@/modules/payment/components';
import { paymentOptions, paymentMethodStatus, isPaystackOption } from '@/modules/payment';
import { action, getter, mutation } from '@/modules/payment/store/const';
import { deviceType, helper } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';
import { getter as platformGetter, mutation as platformMutation } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import PageMixin from '@/components/Pages/Page.mixin';

export default {
    name: 'Paymentcomponent',
    components: { GeneralPaymentcomponent, Mtnnigeriaairtime, PaystackDeposit, Spinner, UserPhoneOperator },
    mixins: [PageMixin],
    props: {
        data: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    data() {
        return {
            isSelectVisible: true,
            isPresto: deviceType.isPresto(),
            paymentMethodStatus,
        };
    },
    computed: {
        ...mapState({
            selectedProviderId: (state) => state.payment.deposit.providers.selectedId,
        }),
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            isDepositComponentVisible: getter.GET_IS_DEPOSIT_COMPONENT_VISIBLE,
            providersList: getter.GET_PROVIDERS_OPTIONS,
            selectedProvider: getter.GET_SELECTED_PROVIDER,
            preference: platformGetter.GET_PREFERENCE,
            isVisibilityRulePassed: platformGetter.GET_IS_PAYMENT_COMPONENT_VISIBILITY_RULE_PASSED,
            isNeedToSendVisibilityEvent: platformGetter.GET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            showLink: getter.SHOW_LINK,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
            isProviderUnavailable: getter.IS_PROVIDER_UNAVAILABLE,
        }),
        showPaystack() {
            return isPaystackOption(this.selectedProvider.depositTypeName);
        },
        identifier() {
            const { identifier } = this.data || {};
            if (identifier && identifier.toLowerCase() === 'none') {
                return undefined;
            }
            return identifier;
        },
        showMtnNigeriaAirtime() {
            const identifier = this.selectedProvider.depositTypeName || this.identifier;
            return (identifier && identifier.toUpperCase()) === paymentOptions.NIGERIA_MTN_AIRTIME_MADAPI.toUpperCase();
        },
        isVisible() {
            // hide component only if close icon (this.isSelectVisible === true) visible and close click
            return this.isSelectVisible ? this.isDepositComponentVisible : true;
        },
        isProvidersLoading() {
            return this.isLoading(action.FETCH_PROVIDERS_OPTIONS);
        },
        isUnavailableSelected() {
            return !!this.selectedProviderId && !Object.keys(this.selectedProvider).length;
        },
        isHiddenInPrestoMode() {
            // hide component at home page(with visible select) in presto mode
            return this.isPresto && this.isSelectVisible;
        },
        isNeedToAddInDOM() {
            return this.isVisible && !this.isHiddenInPrestoMode && !this.isUnavailableSelected && this.providersList.length > 0;
        },
        showComponent() {
            return !this.isProvidersLoading && this.providersList && this.providersList.length;
        },
        isVisibleWithSelect() {
            return this.isSelectVisible && !this.isProvidersLoading && this.isDepositComponentVisible;
        },
        isNeedToResetVisibilityEventFlag() {
            return !this.isVisibilityRulePassed && this.isVisibleWithSelect;
        },
        notOperational() {
            const { status } = this.selectedProvider;
            return status && status !== paymentMethodStatus.OPERATIONAL;
        },
        isBgVisible() {
            if (this.data?.showBackground === undefined || this.data?.showBackground === null) {
                return this.isSelectVisible;
            }

            return !!this.data?.showBackground;
        },
        showMethodInfo() {
            const { description } = this.selectedProvider;
            return description && description.length && !this.showLink;
        },
    },
    watch: {
        providersList: {
            immediate: true,
            handler(list) {
                if (list?.length) {
                    const invalidIdentifier = helper.createUUID();
                    let id = invalidIdentifier;
                    const preferedId = this.getPreferredId({ providers: list });
                    if (this.identifier) {
                        const { id: providerId } =
                            list.find(({ showLink, depositTypeName }) => {
                                if (!depositTypeName || !this.identifier) return false;
                                return depositTypeName.toLowerCase() === this.identifier.toLowerCase() && !showLink;
                            }) || {};
                        id = providerId || invalidIdentifier;
                    } else {
                        id = preferedId;
                    }
                    this.isSelectVisible = !id || !this.identifier;
                    this.setSelectedProvider(id);
                } else {
                    if (!this.isProvidersLoading) {
                        this.isSelectVisible = false;
                    }
                }
            },
        },
        isNeedToResetVisibilityEventFlag: {
            immediate: true,
            handler(need) {
                if (need) {
                    this.setIsNeedToSendVisibilityEvent(true);
                }
            },
        },
        isVisibleWithSelect: {
            immediate: true,
            handler(isVisible) {
                if (isVisible && this.isNeedToSendVisibilityEvent) {
                    this.$gtm.query({
                        event: 'show_deposit_with_select',
                    });
                    this.setIsNeedToSendVisibilityEvent(false);
                }
            },
        },
    },
    created() {
        this.$store.dispatch(action.FETCH_PROVIDERS_OPTIONS);
    },
    beforeDestroy() {
        this.setSelectedProvider(null);
        if (this.isNeedToResetVisibilityEventFlag) {
            this.setIsNeedToSendVisibilityEvent(true);
        }
    },
    methods: {
        ...mapMutations({
            setDepositComponentVisible: mutation.SET_DEPOSIT_COMPONENT_IS_VISIBLE,
            setSelectedProvider: mutation.SET_SELECTED_PROVIDER,
            setIsNeedToSendVisibilityEvent: platformMutation.SET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT,
        }),
        getPreferredId({ providers }) {
            const { deposit_option: depositOption } = this.preference;
            const { id } = providers.find((provider) => depositOption && provider.depositTypeName === depositOption) || {};
            return id || null;
        },
        onCloseClick() {
            this.setDepositComponentVisible(false);
            this.$gtm.query({
                event: 'deposit_close',
            });
            this.setIsNeedToSendVisibilityEvent(true);
        },
    },
};
</script>

<style scoped lang="scss">
.payment-component-wrapper {
    margin: 10px;

    .simple-page & {
        ::v-deep {
            margin: 0;
        }
    }
}

.payment-component {
    background: $payment-component-background;

    &-with-select {
        padding: 16px;
    }

    &-specific {
        padding: 10px;
    }
}

.method-info {
    @extend %body-normal-font-400;
    margin-top: 18px;
}

.method-info-note {
    & + & {
        margin-top: 8px;
    }
}

.method-notify {
    margin-bottom: 10px;
}

.user-operator {
    border: 1px solid #ebebeb;
    margin: 0 0 12px;
}
</style>
