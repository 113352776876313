<template>
    <div v-if="isPresto" class="selector-container table" data-test-id="sportCategory">
        <div class="row-cell align-middle">
            <div class="category-selector presto" @click="togglePrestoDropdown()">
                {{ currentCategory.name }}
                <svgIcon :icon-id="icon" class="icon-size-small category-selector-icon" />
            </div>
            <ul v-show="isOpenedDropdown" class="dropdown">
                <li
                    v-for="category in categories"
                    :key="`left-menu-category-${category.id}`"
                    :class="{ selected: category.id === currentCategoryId }"
                    @click="setCurrentCategoryId(category.id)"
                >
                    {{ category.name }}
                </li>
            </ul>
        </div>
        <div class="row-cell align-middle">
            <span class="close" @click="hideLeftMenu">
                <SvgIcon icon-id="icon-close" />
            </span>
        </div>
    </div>
    <div v-else class="menu-categories">
        <Tabs
            :tabs="categoryTabs"
            :active="activeTab"
            :bottom-border="false"
            :top-border="false"
            class="category-tabs"
            tab-type="round"
            is-dark
            scrollable
            @select="setCurrentTab"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { deviceType } from '@/modules/core';

import { mutation, getter as generalGetter, action as storeAction } from '@/store/const';
import { Tabs } from '@/modules/core/components';
import { routeName } from '@/router/const-name';

export default {
    name: 'LeftMenuCategories',
    components: { Tabs },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            isOpenedDropdown: false,
        };
    },
    computed: {
        ...mapGetters({
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            currentCategory: generalGetter.GET_CURRENT_CATEGORY,
            categories: generalGetter.GET_SORTED_CATEGORIES,
        }),
        icon() {
            return !this.isOpenedDropdown ? 'arrow_down' : 'icon-arrow-up';
        },
        categoryTabs() {
            return this.categories.map((category) => {
                return {
                    key: category.id,
                    iconId: this.$t(`project.sports.${category.id}.icon`),
                    iconClass: 'icon-size-medium',
                    text: category.name,
                };
            });
        },
        activeTab() {
            return this.categoryTabs.find((tab) => tab.key === this.currentCategoryId);
        },
    },
    methods: {
        setCurrentCategoryId(value) {
            this.$store.commit(mutation.SET_CURRENT_CATEGORY, value);
            this.trackMainNav();
            if (this.isPresto) {
                this.$router.push({ name: routeName.UPCOMING, params: { categoryId: value } });
                this.isOpenedDropdown = false;
                this.hideLeftMenu();
            }
        },
        togglePrestoDropdown() {
            this.isOpenedDropdown = !this.isOpenedDropdown;
            this.trackMainNav();
        },
        trackMainNav() {
            this.$gtm.query({
                event: 'main_nav',
                click_text: 'change category',
            });
        },
        setCurrentTab(menuItem) {
            this.$store.commit(mutation.SET_CURRENT_CATEGORY, menuItem.key);
            this.$store.dispatch(storeAction.GET_CATEGORY_ACTIVE_REGIONS, menuItem.key);
            this.trackMainNav();
            this.$emit('updatedCategory', menuItem.key);
        },
        hideLeftMenu() {
            this.$emit('hideLeftMenu');
        },
    },
};
</script>

<style scoped lang="scss">
.menu-categories {
    display: flex;
    gap: 8px;
    background-color: $left-menu-category-color;
    padding: 8px;
    margin-top: 8px;
    overflow-x: scroll;
    scrollbar-width: none;

    .wrapper {
        .category {
            @extend %body-normal-font-400;
            padding: 15px 0;
            min-width: 128px;
            background-color: $left-menu-background-color;
            border: 1px solid $left-menu-item-border;
            color: $dark-grey-2;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            cursor: pointer;

            .icon {
                fill: #aaaeb0;
            }

            &.selected {
                font-weight: 500;
                background-color: $left-menu-category-selected-background;
                border: 1px solid $left-menu-category-selected-border;
                color: $green;

                .icon {
                    fill: $green;
                }
            }

            @include maxbasic {
                padding: 15px 16px;
                min-width: auto;
            }
        }
    }

    .category-tabs {
        ::v-deep .tabs-menu .tabs-selector .tab-item {
            min-width: 128px;
        }
    }
}

.selector-container {
    background: $dark-menu-category-color;
    padding: 8px 12px;
    border-bottom: $border;
    min-height: 54px;

    > div:first-child {
        width: 100%;
    }

    .close {
        cursor: pointer;
        padding-left: 10px;

        svg {
            fill: $left-menu-svg-color;
            padding: 5px;
            width: 22px;
            height: 22px;
        }
    }
}
// only_mini
.category-selector {
    position: relative;
    background-color: $left-menu-headline-text-color;
    color: $dark-grey-2;
    border: 1px solid $dark-menu-item-border;
    font-size: 14px;
    font-weight: normal;
    padding: 6px 8px;
    width: 100%;
    height: 35px;
    border-radius: $border-radius;
    cursor: pointer;

    .category-selector-icon {
        fill: $left-menu-svg-color;
    }
}

.dropdown {
    border: 1px solid $left-menu-dropdown-border;
    list-style: none;

    li {
        padding-left: 0.3rem;
        cursor: pointer;
        color: $left-menu-svg-color;

        &:hover,
        &.selected {
            background: $field-focus;
            color: $white-text;
        }
    }
}

.category-selector-icon {
    position: absolute;
    right: 0.55rem;
    top: 0.55rem;
}
</style>
