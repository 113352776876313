<template>
    <router-link :to="link" :class="{ selected }" class="menu-tile">
        <div class="tile">
            <div v-if="item.badge && !isPresto" :style="{ backgroundColor: item.badgeColor ? item.badgeColor : '' }" class="tile-badge">
                {{ item.badge }}
            </div>
            <SvgIcon v-if="item.icon" :icon-id="item.icon" :class="iconClass" class="icon" />
            <div v-if="withCount" class="count">{{ item.count.text }}</div>
        </div>
        <div class="label">{{ item.text }}</div>
        <Badge v-if="item.badge && isPresto" class="badge" :text="item.badge" type="new-messages" :bg-color="item.badgeColor" />
    </router-link>
</template>

<script>
import { deviceType } from '@/modules/core';
import Badge from '@/components/Badge.vue';

export default {
    name: 'MenuTile',
    components: { Badge },
    props: {
        selected: {
            type: Boolean,
            default: false,
        },
        link: {
            type: [String, Object],
            default: () => ({}),
        },
        item: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        withCount() {
            return this.item.count && this.item.count.text;
        },
        iconClass() {
            if (this.withCount) {
                return 'icon-size-very-small';
            }
            return this.isPresto ? 'icon-size-medium' : 'icon-size-huge';
        },
    },
};
</script>

<style scoped lang="scss">
.menu-tile {
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    color: $dark-grey-2;

    &:hover {
        color: $dark-grey-2;
    }

    @include only_mini {
        flex-direction: row;
        align-items: center;
        gap: 0;
        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    .tile {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 44px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid $left-menu-item-border;
        background-color: $left-menu-background-color;
        padding: 8px 10px;
        gap: 2px;

        @include only_mini {
            display: block;
            text-align: center;
            height: 44px;
        }

        .icon {
            fill: $dark-grey-2;

            &.icon-size-huge {
                height: 20px;
                min-height: 20px;
                width: 20px;
                max-width: 20px;
            }
            &.icon-size-medium {
                @include only_mini {
                    margin-top: 4px;
                }
            }
            &.icon-size-very-small {
                height: 10px;
                min-height: 10px;
                width: 10px;
                max-width: 10px;
            }

            @include only_mini {
                display: block;
                margin: auto;
            }
        }
        .count {
            font-size: 12px;

            @include only_mini {
                display: block;
                text-align: center;
            }
        }

        .tile-badge {
            color: $left-menu-background-color;
            background-color: $green;
            font-size: 10px;
            position: absolute;
            top: -1px;
            right: -1px;
            line-height: 10px;
            padding: 2px 3px;
            border-radius: 0 4px;
        }

        @include only_mini {
            margin-right: 12px;
            height: 30px;
            padding: 2px;
            width: 60px;
            min-width: 60px;
        }
    }

    .label {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        align-self: center;

        @include only_mini {
            text-align: left;
            width: auto;
            white-space: normal;
        }
    }

    &.selected {
        .tile {
            border-color: $left-menu-category-selected-border;
            background-color: $left-menu-category-selected-background;

            .icon {
                fill: $green;
            }
            .count {
                color: $green;
            }
        }

        .label {
            font-weight: 500;
            color: $green;
        }
    }
    .badge {
        margin-left: 12px;
    }
}
</style>
