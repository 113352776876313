const cmsPublic = '/cms/public';
const userAccount = '/user/account';
const messaging = 'api/messaging/v1/messages';
const messagingV2 = 'api/messaging/v2/messages';
const ledgerApi = 'api/ledger';
const userApi = 'api/user/v1';
const userApiV2 = 'api/user/v2';
const authApi = 'api/auth/v1';
const regionApi = 'api/region/v2';
const cmsApi = '/api/cms/v2';
const preference = 'api/preference/v1';
const casinoIntegrationV1 = '/api/integration/v1';
const casinoLauncherV2 = '/api/integration/v2/launch-url';
const strapiApi = env.VUE_APP_STRAPI_API || `/strapi`;
const progressiveJackpotApi = 'api/progressive-jackpot/v1';
const brandApi = '/api/brand/v1';

export const cms = {
    getAnnouncement: `${cmsApi}/announcements`,
    getCmsComponentData: `${cmsPublic}/getCmsComponentData`,
    getCmsDataText: `${cmsPublic}/getCmsComponentDataById`,
    getCmsPage: `${cmsPublic}/getPageCms`,
    getComponentData: `${cmsPublic}/getComponentData`,
    getComponentDatav1: `${preference}/component-data`,
    getDerivedData: `${preference}/user-derived-data`,
    getModalWindow: `${cmsPublic}/modalWindow`,
    putDerivedData: `${preference}/user-derived-data`,
};
export const strapi = {
    getConfig: `${strapiApi}/api/jurisdictions`,
    getPagePreview: `${strapiApi}/api/draft-preview`,
    getPages: `${strapiApi}/api/pages`,
    getCasinoGames: `${strapiApi}/api/casino-games`,
    getCasinoGamesCategories: `${strapiApi}/api/casino-game-categories`,
    getCasinoGamesBrands: `${strapiApi}/api/casino-game-brands`,
};

export const user = {
    register: `${userApiV2}/signup-and-authenticate`,
    changePassword: `${userApi}/password`,
    resetPassword: `${userApiV2}/password/reset`,
    verifyAccount: `${userAccount}/verifyAccount`,
    verifyAccountBySMS: `${userApi}/verify-by-hash-and-authenticate`,
    kycVerification: `${userApi}/kyc/verification`,
    updatePassword: `${userApiV2}/update-password`,

    // naming should be discussed with platform along with BP-16235, BP-19201
    getMessages: `${messaging}/list`,
    updateMessage: `${messaging}/list`,
    // naming should be discussed ... BP-16235, BP-19201

    getOnsiteMessagesAndCount: `${messagingV2}/active`,
    selfExclude: `${userApi}/self-exclude`,
};
export const auth = {
    login: `${userApi}/authenticate`,
    logout: `${authApi}/logout`,
    preregistration: `${preference}/preregistration`,
};
export const ledger = {
    userBalance: `${ledgerApi}/v1/funds/balance`,
    getStatement: `${ledgerApi}/v1/funds/transactions/statement`,
};

export const casino = {
    getCasinoGames: `${casinoIntegrationV1}/aggregator/games`,
    getCashInTaxPreview: `${casinoIntegrationV1}/conversion/cash-in/tax/preview`,
    getChipsTopUpTaxPreview: `${casinoIntegrationV1}/conversion/top-up/tax/preview`,
    chipsTopUp: `${casinoIntegrationV1}/conversion/top-up`,
    chipsCashIn: `${casinoIntegrationV1}/conversion/cash-in`,
    casinoLauncherV2,
};
export const jurisdiction = {
    getRegions: `${regionApi}/regions`,
};
export const affiliates = {
    setAffiliateId: '/ab/',
};

export const pawapass = {
    verifications: '/api/kyc-verification/v1/pawapass/verifications',
};

export const campaign = {
    eligibility: '/api/campaign/v1/campaign/eligibility',
};

export const progressiveJackpots = {
    winners: `${progressiveJackpotApi}/jackpot/last-ended`,
    active: `${progressiveJackpotApi}/jackpot/active`,
};

export const brandEndpoints = {
    countries: `${brandApi}/countries/betpawa`,
};

export default {
    cms,
    user,
    auth,
    casino,
    jurisdiction,
    affiliates,
    progressiveJackpots,
    brandEndpoints,
};
