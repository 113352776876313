import Vue from 'vue';
import Vuex from 'vuex';

import coreStore from '@/modules/core/store';
import helper from '@/modules/core/utils/helper';

import paymentStore from '@/modules/payment/store';
import { mutation as paymentMutation } from '@/modules/payment/store/const';

import sportStore from '@/modules/sport/store';
import { mutation as sportMutation } from '@/modules/sport/store/const';
import { betStatus, betType, endpoints as sportEndpoints, myBetsType } from '@/modules/sport';
import { action as betslipAction, mutation as betslipMutation } from '@/modules/sport/store/modules/betslip/const';
import platformStore from '@/modules/platform/store';
import { action as platformAction, getter as platformGetter, mutation as platformMutation } from '@/modules/platform/store/const';
import { mutation as authMutation } from '@/modules/platform/store/modules/auth/const';
import { mutation as messagingMutation } from '@/modules/platform/store/modules/messaging/const';

import translationsStore from '@/store/modules/translations';
import { mutation as translationsMutation } from '@/store/modules/translations/const';

import casinoStore from '@/store/modules/casino';
import { mutation as casinoMutation } from '@/store/modules/casino/const';

import { action, mutation, getter } from '@/store/const';

import { MENUS } from '@/js/sheetdump';
import { toolbarIconType } from '@/js/casino-const';

import SnapshotToStorage from './plugins/SnapshotToStorage';
import { countryISOMap } from '@/modules/core/country-iso-map';

Vue.use(Vuex);

const IS_DEV = env.VUE_APP_ENV !== 'production';

const snapshotToStorage = new SnapshotToStorage({
    isDev: IS_DEV,
    immediate(storeMutation) {
        switch (storeMutation.type) {
            case translationsMutation.SET_LANGUAGE:
                return true;
        }
        return false;
    },
    filter(storeMutation) {
        return [
            translationsMutation.SET_LANGUAGE,
            mutation.SET_CURRENT_CATEGORY,
            mutation.UPDATE_TERMS_DECLINE_STATUS,
            platformMutation.SET_CONFIG,
            platformMutation.SET_AFFILIATE_ID,
            authMutation.SET_OTP_LIMITS,
            platformMutation.SET_AGI_SETTINGS,
            platformMutation.SET_USER_STATE,
            platformMutation.SET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT,
            platformMutation.SET_CAMPAIGN_ELIGIBILITY,
            platformMutation.SET_SURVEY,
            messagingMutation.SET_ONSITE_MESSAGES_AND_COUNT,
            messagingMutation.SET_MESSAGES,
            authMutation.SET_PHONE_NUMBER,
            authMutation.SET_OTP_TIMER,
            authMutation.SET_AUTH,
            authMutation.SET_OTP_ATTEMPTS,
            authMutation.RESET_OTP_ATTEMPTS,
            authMutation.SET_RESET_PASSWORD_TRACKING_ID,
            sportMutation.SET_JACKPOT_SELECTED,
            sportMutation.UPDATE_CASHOUT_POLLING,
            betslipMutation.CHANGE_BETSLIP_BET,
            betslipMutation.REMOVE_BETSLIP_BET,
            betslipMutation.REMOVE_ALL_BETSLIP_BETS,
            betslipMutation.SET_BETSLIP_STAKE,
            betslipMutation.RESET_BETSLIP_STAKE,
            betslipMutation.RESET_BETSLIP_STATUS,
            betslipMutation.SET_BETSLIP_ACCEPT_ANY_PRICE,
            betslipMutation.SET_VIRTUAL_BETSLIP_SEASON,
            betslipMutation.RESET_VIRTUAL_BETSLIP_SEASON,
            betslipMutation.UPDATE_SELECTED_PRICES,
            betslipMutation.ADD_BETSLIP_BET,
            betslipMutation.SET_BETSLIP_BETS,
            paymentMutation.SET_FIRST_DEPOSIT_DATA,
            paymentMutation.RESERVE_DEPOSIT_ID,
            paymentMutation.SET_DEPOSIT_COMPONENT_IS_VISIBLE,
            paymentMutation.RESET_DEPOSIT,
            sportMutation.SET_SAVED_EVENT_FILTERS,
            sportMutation.SET_SAVED_EVENT_SORTING,
            casinoMutation.SET_GAMES_SORT_BY,
            casinoMutation.SET_FAVOURITE_GAMES_SORT_BY,
            casinoMutation.SET_GAMES,
            casinoMutation.SET_CASINO_LAYOUT,
        ].includes(storeMutation.type);
    },
    reducer(state) {
        return {
            sport: {
                betslip: {
                    selectedEventId: state.sport.betslip.selectedEventId,
                    selectedPriceId: state.sport.betslip.selectedPriceId,
                    regular: state.sport.betslip.regular,
                    virtual: state.sport.betslip.virtual,
                },
                jackpot: {
                    selected: state.sport.jackpot.selected,
                    uniqueTicketId: state.sport.jackpot.uniqueTicketId,
                },
                myBets: {
                    offerPolling: state.sport.myBets.offerPolling,
                },
                ui: {
                    savedEventFilters: state.sport.ui.savedEventFilters,
                    savedEventSorting: state.sport.ui.savedEventSorting,
                },
            },
            currentCategoryId: state.currentCategoryId,
            platform: {
                config: state.platform.config,
                affiliate: state.platform.affiliate,
                settings: {
                    preference: {
                        first_bet: state.platform.settings.preference.first_bet || null,
                    },
                },
                content: {
                    isNeedToSendPaymentComponentVisibilityEvent: state.platform.content.isNeedToSendPaymentComponentVisibilityEvent,
                },
                auth: {
                    phoneNumber: state.platform.auth.phoneNumber,
                    otpTimer: state.platform.auth.otpTimer,
                    nextCheckBalanceTimestamp: state.platform.auth.nextCheckBalanceTimestamp,
                    accountBalance: state.platform.auth.accountBalance,
                    isBalanceReady: state.platform.auth.isBalanceReady,
                    otpAttempts: state.platform.auth.otpAttempts,
                    otpLimits: state.platform.auth.otpLimits,
                    nextOtpAttemptTimestamp: state.platform.auth.nextOtpAttemptTimestamp,
                    resetPasswordTrackingId: state.platform.auth.resetPasswordTrackingId,
                },
                campaignEligibility: state.platform.campaignEligibility,
                survey: state.platform.survey,
                messaging: {
                    nextCheckTimestamp: state.platform.messaging.nextCheckTimestamp,
                    notSeen: state.platform.messaging.notSeen,
                },
            },
            payment: {
                firstDeposit: state.payment.firstDeposit,
                /**
                 * @deprecated since v3.14.x - use `firstDeposit` instead.
                 */
                lastDepositProvider: state.payment.lastDepositProvider,
                reservedDeposit: state.payment.reservedDeposit,
                deposit: {
                    providers: {
                        isDepositComponentVisible: state.payment.deposit.providers.isDepositComponentVisible,
                    },
                },
            },
            translations: {
                language: state.translations.language,
                persist: state.translations.persist,
            },
            isTermsDeclined: state.isTermsDeclined || false,
            casino: {
                layout: state.casino.layout || toolbarIconType.TILE,
                games: { data: state.casino.games.data, expiration: state.casino.games.expiration },
                gamesSortBy: state.casino.gamesSortBy,
                favoriteGamesSortBy: state.casino.favoriteGamesSortBy,
            },
        };
    },
});

export default new Vuex.Store({
    state: {
        title: 'State',
        menus: MENUS,
        notification: {
            notifications: [],
            trigger: false,
        },
        countries: {},
        currentCategoryId: null,
        currentTournaments: [],
        ui: {
            liveRefreshRate: 3,
            sidebarOpen: false,
            betslipOpen: false,
            topMenuOpen: false,
            bottomMenuOpen: false,
            mobileSearchOpen: false,
            leftMenuExpandedCountries: [], // TODO: merge with menus state
            showLeftMenuOther: false, // TODO: merge with menus state,
        },
        showTopMenu: false,
        wazdanGames: {},
        gameLaunchError: null,
        isTermsDeclined: false,
    },
    getters: {
        getNotificationTypes: (state) => state.notification.notifications.map((item) => item.type),
        [getter.GET_MY_BETS_MENU]: ({ menus, platform }) => {
            return menus.myBets
                .map((menu) => ({ ...menu, text: Vue.$t(menu.tKey) }))
                .filter((menu) => !menu.enabler || helper.pathChecker(menu.enabler, platform.settings));
        },
        [getter.GET_MENU_ITEM_BY_BETSLIP]:
            (state, getters) =>
            ({ betslipType = '', status = '' }) => {
                const myBetsMenu = getters[getter.GET_MY_BETS_MENU];
                const defaultItem = myBetsMenu.find((item) => item.default);
                switch (betslipType) {
                    case betType.REAL:
                        if (status === betStatus.PENDING) {
                            return myBetsMenu.find((item) => item.key === myBetsType.PENDING) || defaultItem;
                        }
                        return myBetsMenu.find((item) => item.key === myBetsType.SETTLED) || defaultItem;
                    case betType.VIRTUAL:
                        return myBetsMenu.find((item) => item.key === myBetsType.VIRTUAL) || defaultItem;
                    case betType.JACKPOT:
                        return myBetsMenu.find((item) => item.key === myBetsType.JACKPOT) || defaultItem;
                }

                return defaultItem;
            },
        [getter.GET_SORTED_CATEGORIES]: (state, getters) => {
            const rawCategories = getters[platformGetter.GET_CATEGORIES];
            return [...rawCategories].sort((a, b) => a.priority - b.priority);
        },
        [getter.GET_CURRENT_CATEGORY_ID]: (state, getters) => {
            const stateCategoryId = state.currentCategoryId;
            const stateCategoryData = getters[getter.GET_SORTED_CATEGORIES].find(
                (category) => String(category.id) === String(stateCategoryId)
            );

            if (stateCategoryId && stateCategoryData) {
                return stateCategoryId;
            }

            return getters[getter.GET_SORTED_CATEGORIES][0]?.id;
        },
        [getter.GET_CURRENT_CATEGORY]: (state, getters) => {
            const currentCategoryId = getters[getter.GET_CURRENT_CATEGORY_ID];
            const categories = getters[getter.GET_SORTED_CATEGORIES];
            return categories.find((category) => String(category.id) === String(currentCategoryId)) || {};
        },
        [getter.GET_QUICK_MAIN_MENU]: (state) => {
            return state.menus.quickMainMenu;
        },
    },
    mutations: {
        [mutation.SET_CURRENT_CATEGORY](state, newValue) {
            state.currentCategoryId = newValue;
        },
        [mutation.SET_TOURNAMENTS](state, newValue) {
            state.currentTournaments = newValue;
        },
        [mutation.SET_COUNTRIES](state, newValue) {
            state.countries = newValue;
        },
        setBetslipState(state, value) {
            state.ui.betslipOpen = value;
        },
        [mutation.TOGGLE_BETSLIP_STATE](state) {
            state.ui.betslipOpen = !state.ui.betslipOpen;
        },
        toggleSidebar(state) {
            state.ui.sidebarOpen = !state.ui.sidebarOpen;
        },
        setSidebarState(state, value) {
            state.ui.sidebarOpen = value;
        },
        toggleMobileSearch(state) {
            state.ui.mobileSearchOpen = !state.ui.mobileSearchOpen;
            state.ui.topMenuOpen = false;
        },
        closeMobileSearch(state) {
            state.ui.mobileSearchOpen = false;
        },
        setTopMenuState(state, value) {
            state.ui.topMenuOpen = value;
        },
        setBottomMenuState(state, value) {
            state.ui.bottomMenuOpen = value;
        },
        [mutation.CLOSE_TOP_MENU](state) {
            state.ui.topMenuOpen = false;
        },

        [mutation.TOGGLE_LEFT_MENU_COUNTRY](state, countryId) {
            const expandedCountries = state.ui.leftMenuExpandedCountries;
            const index = expandedCountries.indexOf(countryId);
            if (index < 0) {
                Vue.set(expandedCountries, expandedCountries.length, countryId);
            } else {
                Vue.delete(expandedCountries, index);
            }
        },
        [mutation.TOGGLE_LEFT_MENU_OTHER](state) {
            state.ui.showLeftMenuOther = !state.ui.showLeftMenuOther;
        },
        [mutation.ADD_NOTIFICATION](state, { type, data, status = 'success', excludedRoutes = [] }) {
            state.notification.notifications.push({ type, data, status, excludedRoutes });
            if (data && data.trigger) {
                state.notification.trigger = true;
            }
        },
        [mutation.CLEAR_NOTIFICATIONS](state) {
            state.notification.notifications = [];
            state.notification.trigger = false;
        },
        [mutation.TOGGLE_NOTIFICATION](state) {
            state.notification.trigger = !state.notification.trigger;
        },
        [mutation.UPDATE_TERMS_DECLINE_STATUS](state, payload) {
            state.isTermsDeclined = payload;
        },
    },
    actions: {
        [action.SET_TURNSTILE]({ commit }, value) {
            commit(mutation.SET_TURNSTILE, value);
        },
        [action.GET_CATEGORY_ACTIVE_REGIONS]({ commit, rootGetters, dispatch }, categoryId) {
            const competitionIcons = rootGetters[platformGetter.GET_JURISDICTION_CONFIG].globalConfig.competitionIcons;

            function getCompetitionIconUrl({ id }) {
                if (id in competitionIcons) {
                    return competitionIcons[id];
                }

                return '';
            }

            return Vue.$http
                .get(sportEndpoints.pricing.getCategoryActiveRegions + `/${categoryId}`)
                .then(({ data }) => {
                    const { withRegions, onlyMeta } = data;
                    const {
                        liveEventCount = 0,
                        upcomingEventCount = 0,
                        boostedEventCount = 0,
                        hotEventCount = 0,
                        regions = [],
                    } = withRegions[0] ?? {};
                    const upcomingEventCountPerCategory = getEventTypeCountPerCategory('upcomingEventCount', onlyMeta, withRegions);
                    const liveEventCountPerCategory = getEventTypeCountPerCategory('liveEventCount', onlyMeta, withRegions);
                    const popularEventCountPerCategory = getEventTypeCountPerCategory('hotEventCount', onlyMeta, withRegions);
                    const boostedEventCountPerCategory = getEventTypeCountPerCategory('boostedEventCount', onlyMeta, withRegions);
                    const tournaments = [];

                    const sortedCountries = regions
                        .map((country) => {
                            const currentCountry = {
                                region: country.region.name,
                                regionSlug: country.region.slug,
                                parentRegionSlug: country.region.parentSlug,
                            };
                            const countryIcon = {
                                src: currentCountry.regionSlug ? `img/flags/${currentCountry.regionSlug}.png` : '',
                                altSrc: currentCountry.parentRegionSlug ? `img/flags/${currentCountry.parentRegionSlug}.png` : '',
                            };
                            let countryEventCount = 0;
                            let countryBoostedEventCount = 0;
                            let countryPopularEventCount = 0;
                            let countryLiveEventCount = 0;
                            let countryPriority = 0;
                            const currentCompetitions = [];
                            const countryIsoCode = countryISOMap[currentCountry.region] || '';
                            if (country.competitions.length) {
                                country.competitions.forEach(({ competition, ...moreCompetitionDetails }) => {
                                    const competitionIconUrlFromStrapi = getCompetitionIconUrl(competition);
                                    const hasCompetitionIconUrlFromStrapi = !!competitionIconUrlFromStrapi;
                                    const competitionData = {
                                        ...competition,
                                        ...moreCompetitionDetails,
                                        icon: {
                                            ...(!hasCompetitionIconUrlFromStrapi && !!countryIsoCode
                                                ? { isoCode: countryIsoCode }
                                                : { src: competitionIconUrlFromStrapi || countryIcon.src, altSrc: countryIcon.altSrc }),
                                        },
                                    };
                                    if (countryPriority < competition.priority) {
                                        countryPriority = competition.priority;
                                    }
                                    if (competition.priority) {
                                        tournaments.push({
                                            ...competitionData,
                                            ...currentCountry,
                                        });
                                    }
                                    countryEventCount += moreCompetitionDetails.eventCount;
                                    countryBoostedEventCount += moreCompetitionDetails.boostedEventCount;
                                    countryPopularEventCount += moreCompetitionDetails.popularEventCount;
                                    countryLiveEventCount += moreCompetitionDetails.liveEventCount;
                                    currentCompetitions.push(competitionData);
                                });
                            }
                            currentCompetitions.sort((a, b) => b.priority - a.priority);
                            return {
                                ...country,
                                icon: countryIsoCode ? { isoCode: countryIsoCode } : countryIcon,
                                popularEventCount: countryPopularEventCount,
                                boostedEventCount: countryBoostedEventCount,
                                liveEventCount: countryLiveEventCount,
                                eventCount: countryEventCount,
                                priority: countryPriority,
                                competitions: currentCompetitions,
                            };
                        })
                        .sort((a, b) => a.region.name.localeCompare(b.region.name));
                    const updatedTournaments = tournaments.flat().sort((a, b) => b.priority - a.priority);
                    commit(mutation.SET_TOURNAMENTS, updatedTournaments);
                    commit(mutation.SET_COUNTRIES, sortedCountries);
                    commit(sportMutation.UPDATE_SPORTS, {
                        boostedEventsCount: { ...boostedEventCountPerCategory, [categoryId]: boostedEventCount },
                        upcomingEventsCount: { ...upcomingEventCountPerCategory, [categoryId]: upcomingEventCount },
                        popularEventsCount: { ...popularEventCountPerCategory, [categoryId]: hotEventCount },
                        liveEventsCount: { ...liveEventCountPerCategory, [categoryId]: liveEventCount },
                    });
                })
                .catch((error) => console.error(`${action.GET_CATEGORY_ACTIVE_REGIONS} Response Error`, [error, categoryId]));
        },
        [action.TOGGLE_SIDEBAR]({ commit }) {
            commit('toggleSidebar');
        },
        [action.SET_SIDEBAR_STATE]({ commit }, value) {
            commit('setSidebarState', value);
        },
        [action.TOGGLE_BETSLIP_STATE]({ commit, dispatch }) {
            dispatch(betslipAction.SELECT_BETSLIP_TYPE);
            commit(mutation.TOGGLE_BETSLIP_STATE);
        },
        [action.SET_BETSLIP_STATE]({ commit, dispatch }, isOpen) {
            dispatch(betslipAction.SELECT_BETSLIP_TYPE);
            commit('setBetslipState', isOpen);
        },
        [action.NOTIFY]({ commit }, payload) {
            commit(mutation.ADD_NOTIFICATION, payload);
        },
        [action.ACCEPT_TERMS]: ({ dispatch }, payload) => {
            dispatch(platformAction.PUT_DERIVED_DATA, payload).then(() => {
                dispatch(platformAction.LOAD_AGI_SETTINGS);
            });
        },
    },
    modules: {
        core: coreStore,
        platform: platformStore,
        sport: sportStore,
        payment: paymentStore,
        translations: translationsStore,
        casino: casinoStore,
    },
    strict: IS_DEV,
    plugins: [snapshotToStorage.plugin],
});

function getEventTypeCountPerCategory(eventType, metaData = [], withRegions = []) {
    return {
        ...metaData.reduce((acc, category) => ({ ...acc, [category.category.id]: category[eventType] }), {}),
        ...withRegions.reduce((acc, category) => ({ ...acc, [category.category.id]: category[eventType] }), {}),
    };
}
