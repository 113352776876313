<template>
    <div>
        <Tabs :tabs="tabs" :active="currentTab" class="virtual-standing-tabs" bottom-border tab-type="text" @select="setCurrentTab">
            <Tab name="standings">
                <VirtualSeasonSelect :season-id="seasonId" />
                <div v-for="league in standings" :key="'standing' + league.league.name" class="separator-wrapper">
                    <div class="separator" @click="toggleLeagueById(league.league.id)">
                        <div class="league-name">
                            <ImageIcon class="icon-region icon-size-small" :src="`img/flags/${virtualLeagueMap[league.league.id]}.png`" />
                            {{ league.league.name }}
                        </div>
                        <SvgIcon
                            class="icon-arrow icon-size-small"
                            :icon-id="showLeague(league.league.id) ? 'arrow_down' : 'arrow_right'"
                        />
                    </div>
                    <transition name="slide">
                        <div v-show="showLeague(league.league.id)">
                            <table class="standings-table">
                                <thead class="standings-head">
                                    <tr>
                                        <th class="left">{{ $t('ui.virtualSports.standings.club') }}</th>
                                        <th class="points">{{ $t('ui.virtualSports.standings.pts') }}</th>
                                        <th>{{ $t('ui.virtualSports.standings.w') }}</th>
                                        <th>{{ $t('ui.virtualSports.standings.d') }}</th>
                                        <th>{{ $t('ui.virtualSports.standings.l') }}</th>
                                        <th>{{ $t('ui.virtualSports.standings.goals') }}</th>
                                        <th>{{ $t('ui.virtualSports.standings.form') }}</th>
                                    </tr>
                                </thead>
                                <tbody v-for="standingItem in league.leagueStandings" :key="'itemStanding' + standingItem.position">
                                    <tr class="standings-row">
                                        <td class="left">
                                            <span class="row-index">{{ standingItem.position }}</span>
                                            <span class="team">{{ league.teams[standingItem.teamId].name }}</span>
                                        </td>
                                        <td class="points">{{ standingItem.points }}</td>
                                        <td>{{ standingItem.won }}</td>
                                        <td>{{ standingItem.drew }}</td>
                                        <td>{{ standingItem.lost }}</td>
                                        <td>{{ standingItem.scored }}:{{ standingItem.conceded }}</td>
                                        <td>
                                            <badge
                                                v-for="(badge, index) in standingItem.form"
                                                :key="standingItem.teamId + badge + standingItem.scored + standingItem.lost + index"
                                                :type="colorBadge(badge)"
                                                mode="micro"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </transition>
                </div>
            </Tab>
            <Tab :name="routeName.MATCH_DAY">
                <VirtualSeasonSelect :season-id="seasonId" />
                <div v-if="standings && standings.length">
                    <div
                        v-for="(matchday, index) of standings[0].gameRounds"
                        :key="`matchday-${matchday.gameRoundName}-${matchday.gameRoundId}-${index}`"
                        class="separator matchday"
                        @click="openMatchday(matchday.gameRoundId)"
                    >
                        {{ $t('ui.virtualSports.standings.matchday') }}:{{ matchday.gameRoundName }}
                        <SvgIcon class="icon-size-small" :icon-id="'arrow_right'" />
                    </div>
                </div>
            </Tab>
        </Tabs>
        <div class="spinner-wrapper">
            <Spinner :listen="standingsSpinnerTrigger" />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { Tab, Tabs } from '@/modules/core/components';
import { VIRTUAL_LEAGUE_MAP } from '@/modules/sport';
import { action as sportAction, getter as sportGetter, mutation as sportMutation } from '@/modules/sport/store/const';
import Badge from '@/components/Badge';
import PageMixin from '@/components/Pages/Page.mixin';
import VirtualSeasonSelect from './VirtualSeasonSelect.vue';
import { routeName } from '@/router/const-name';

export default {
    name: 'VirtualStanding',
    components: { Tabs, Tab, Badge, VirtualSeasonSelect },
    mixins: [PageMixin],
    props: {
        seasonId: {
            type: Number,
            default: 1,
        },
        prevRouteName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            expandedLeagues: [],
            currentTab: {},
            initialLeagueIndex: 0,
            virtualLeagueMap: VIRTUAL_LEAGUE_MAP,
            standingsSpinnerTrigger: sportAction.GET_VIRTUAL_STANDINGS,
            routeName,
        };
    },
    computed: {
        ...mapState({
            standings: (state) => state.sport.virtualSports.standings,
        }),
        ...mapGetters({
            season: sportGetter.GET_SELECTED_VIRTUAL_SEASON,
        }),
        tabs() {
            return [
                { text: this.$t('ui.virtualSports.standings.standings'), key: 'standings', status: 'active' },
                { text: this.$t('ui.virtualSports.standings.matchdays'), key: routeName.MATCH_DAY, status: 'closed' },
            ];
        },
    },
    created() {
        const currentTab = this.tabs.find((tab) => tab.key === this.prevRouteName) || this.tabs[this.initialLeagueIndex];

        this.setCurrentTab(currentTab);
        this.unwatchEvents = this.$watch('standings', (standings) => {
            if (standings && standings.length) {
                this.toggleLeagueById(standings[this.initialLeagueIndex].league.id);
                this.unwatchEvents();
            }
        });
    },
    methods: {
        ...mapMutations({
            setSeason: sportMutation.SET_VIRTUAL_SEASON,
        }),
        toggleLeagueById(id) {
            const leagueIndex = this.expandedLeagues.indexOf(id);
            leagueIndex < 0 ? this.expandedLeagues.push(id) : this.expandedLeagues.splice(leagueIndex, 1);
        },
        showLeague(id) {
            return this.expandedLeagues.includes(id);
        },
        setCurrentTab(event) {
            if (event.key !== this.currentTab.key) {
                this.currentTab = event;
            }
        },
        colorBadge(data) {
            switch (data) {
                case 'D':
                    return 'pending-dark';
                case 'W':
                    return 'win';
                default:
                    return 'lose';
            }
        },
        openMatchday(id) {
            this.$router.push({ name: routeName.MATCH_DAY, params: { seasonId: this.season.id, roundId: id } });
        },
    },
};
</script>

<style scoped lang="scss">
.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.4s, opacity 0.4s;
    max-height: 500px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    max-height: 0;
}

.separator {
    background: white;
    color: black;
    font-size: 14px;
    line-height: 1em;
    padding: 15px 12px;
    margin: 0;
    cursor: pointer;

    @include only_mini {
        .league-name {
            display: inline-block;
        }

        .icon-arrow {
            float: right;
        }
    }

    @include all_but_mini {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        .league-name {
            display: flex;
        }
    }

    .icon-region {
        vertical-align: bottom;
        margin-right: 5px;
    }
}

.separator {
    &-wrapper {
        border-top: 1px solid $separator-border-color;
    }

    &.matchday {
        border-bottom: 1px solid $separator-border-color;
    }

    &.matchday:last-child {
        border-bottom: 0;
    }

    &.matchday:first-child {
        border-top: 1px solid $separator-border-color;
    }
}

.season-select {
    padding: 10px;
    background-color: $menu-over;
}

.standings {
    &-table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;

        .points {
            font-weight: bold;
        }
    }

    &-head {
        background-color: $light-grey-bg;

        th {
            @extend %body-normal-font-400;
            padding: 10px 0;
            color: $grey-text;

            &:first-child {
                padding-left: 10px;
            }

            &:last-child {
                padding-right: 10px;
            }
        }
    }

    &-row {
        border-bottom: 2px solid $menu-over;

        td {
            @extend %body-big-font-400;
            padding: 12px 0;
            color: $grey-text;

            &:first-child {
                padding-left: 10px;
            }

            &:last-child {
                padding-right: 10px;
            }

            .row-index {
                padding-right: 10px;
            }
        }

        .team,
        .points {
            color: $main-text;
        }
    }
}

.left {
    text-align: left;
}

.spinner-wrapper {
    position: relative;
}
</style>
