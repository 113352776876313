export const EventType = {
    UPCOMING: 'UPCOMING',
    LIVE: 'LIVE',
};

export const EventCategory = {
    EVENTS: 'events',
    BOOSTED: 'boosted',
    POPULAR: 'popular',
};

export const EventCategoryCountKeys = {
    POPULAR: 'popularEventCount',
    LIVE: 'liveEventCount',
    DEFAULT: 'eventCount',
};

export const EVENTS_SORTING_ACCEPTABLE_MAP = {
    competitionPriority_DESC: 'competitionPriority_DESC',
    startTime_DESC: 'startTime_DESC',
    startTime_ASC: 'startTime_ASC',
    competitionAbc_DESC: 'competitionAbc_DESC',
    competitionAbc_ASC: 'competitionAbc_ASC',
};

export const DEFAULT_TAKE = 100;

export const SPORT_CATEGORY = {
    FOOTBALL: '2',
    BASKETBALL: '3',
    TENNIS: '452',
};
