<template>
    <div v-if="tabsList.length" class="tabs">
        <Tabs
            :tabs="tabsList"
            :active="currentTab"
            scrollable
            is-dark
            :top-border="false"
            @select="setCurrentTab"
            @tabsHaveScroll="tabsHaveScroll"
        />
        <div
            v-if="showMenuButton"
            v-sniffer="{ handler: closeExtendedFooter }"
            v-click-outside:[true]="closeExtendedFooter"
            :class="{ show: showExtendedMenu }"
            class="extended-menu"
        >
            <MenuTiles :items="menuItems" place-name="productNav" scrollable class="menu-tiles" @onMenuItemClick="closeExtendedFooter" />
            <div class="extended-menu-footer">
                <span data-test-id="navigationExtendedMenuClose" @click="closeExtendedFooter">Close</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { Tabs } from '@/modules/core/components';
import { helper } from '@/modules/core';
import { routeName } from '@/router/const-name';
import MenuTiles from '@/modules/core/components/atoms/MenuTiles.vue';
import { getter as generalGetter } from '@/store/const';
import scrollSniffer from '@/js/directives/ScrollSniffer';
import { getObjectField } from '@/modules/core/utils/helper';
import { getter as sportGetter } from '@/modules/sport/store/const';

const EXTENDED_MENU = {
    key: 'ExtendedMenu',
    iconId: 'arrow_down',
    iconClass: 'icon-size-small',
    sticky: 'sticky-right',
    highlight: true,
};

export default {
    name: 'ProductNav',
    components: { MenuTiles, Tabs },
    directives: {
        sniffer: scrollSniffer,
    },
    data() {
        return {
            showMenuButton: false,
            showExtendedMenu: false,
        };
    },
    computed: {
        ...mapState({
            mobileSearchOpen: (state) => state.ui.mobileSearchOpen,
            headerMenuLinks(state) {
                const { isHeaderLinksEnabled, links } = state.platform.config.headerMenu || {};
                if (!isHeaderLinksEnabled) return [];
                return helper.processStrapiMenuLinks({
                    links,
                    isAuthenticated: this.isAuthenticated,
                    userStatus: this.currentUserStatus,
                    transformer: ({
                        pageId,
                        icon,
                        name,
                        fullPath,
                        path,
                        isTextHidden,
                        hasSportIcon,
                        externalUrl,
                        badge,
                        badgeType,
                        badgeColor,
                        disableHighlight,
                    }) => ({
                        key: pageId,
                        iconId: hasSportIcon ? this.$t(`project.sports.${this.currentCategoryId}.icon`) : icon,
                        iconClass: 'icon-size-small',
                        text: name,
                        isTextHidden,
                        fullPath,
                        path,
                        externalUrl,
                        badge,
                        badgeType,
                        badgeColor,
                        disableHighlight,
                    }),
                    router: this.$router,
                });
            },
        }),
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            brandPreference: getter.GET_BRAND_PREFERENCE,
            currentUserStatus: getter.GET_CURRENT_USER_STATUS,
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            eventsCount: sportGetter.EVENTS_COUNT_PER_TYPE,
        }),
        isEventPageHeaderTabsEnabled() {
            return !!helper.getObjectField(this.brandPreference, 'isEventPageHeaderTabsEnabled', false);
        },
        tabsList() {
            const matchesList = [
                {
                    key: 'Upcoming',
                    iconId: 'icon-clock',
                    iconClass: 'icon-size-small',
                    text: this.$t('menus.upcoming'),
                    fullPath: this.$router.resolve({ name: routeName.UPCOMING }).route.fullPath,
                },
                {
                    key: 'Popular',
                    iconId: 'icon-popular',
                    iconClass: 'icon-size-small',
                    text: this.$t('ui.popular.popularEvents'),
                    fullPath: this.$router.resolve({ name: routeName.POPULAR }).route.fullPath,
                },
                {
                    key: 'Live',
                    iconId: 'icon-live',
                    iconClass: 'icon-size-small',
                    text: this.$t('menus.live'),
                    fullPath: this.$router.resolve({ name: routeName.LIVE }).route.fullPath,
                },
                {
                    key: routeName.BOOSTED_EVENTS,
                    iconId: 'icon-boosted',
                    iconClass: 'icon-size-small',
                    text: this.$t('ui.common.bestOdds'),
                    fullPath: this.$router.resolve({ name: routeName.BOOSTED_EVENTS }).route.fullPath,
                },
            ];
            if (this.showMenuButton) {
                matchesList.push(EXTENDED_MENU);
            }

            const { name } = this.$route;
            const matchesRouteNames = [routeName.COUNTRY, routeName.GROUP];
            const isMatchesList = matchesList.some(({ key }) => name === key) || matchesRouteNames.some((routeName) => routeName === name);
            const isEventPage = name === routeName.EVENT;

            if (isMatchesList && this.isEventPageHeaderTabsEnabled) {
                return matchesList;
            } else if (isEventPage) {
                return [];
            } else {
                return this.showMenuButton ? [...this.headerMenuLinks, EXTENDED_MENU] : this.headerMenuLinks;
            }
        },

        currentTab() {
            if (this.$route.name === routeName.GENERIC_PAGE) {
                const { key } = this.tabsList.find((link) => link.path === this.$route.path) || {};
                return { key };
            }
            // TODO: should be removed in BP-31479 ( Temporary fix for now )
            if ([routeName.GROUP, routeName.COUNTRY].includes(this.$route.name)) {
                return { key: routeName.UPCOMING };
            }
            return { key: this.$route.name };
        },

        menuItems() {
            return this.tabsList
                .filter((item) => item.key !== EXTENDED_MENU.key)
                .map((item) => {
                    return {
                        ...item,
                        link: {
                            path: item.fullPath,
                        },
                        pageId: item.key,
                        isSelected: item.key === this.currentTab.key && !item.disableHighlight,
                        icon: item.iconId,
                        count: this.getMenuItemCount(item.key),
                    };
                });
        },
    },
    methods: {
        trackTopNav(key) {
            this.$gtm.query({
                event: this.$gtm.makeValidEventName(`product_nav_${key}_click`),
                click_element: 'button',
            });
        },
        setCurrentTab(menuItem) {
            if (menuItem.key === EXTENDED_MENU.key) {
                this.showExtendedMenu = true;
                return;
            }

            if (menuItem.externalUrl) {
                helper.openUrl(menuItem.externalUrl, menuItem.target);
            } else {
                this.$router.push({ path: menuItem.fullPath });
            }
            this.trackTopNav(menuItem.key);
        },
        tabsHaveScroll(value) {
            this.showMenuButton = value;
        },
        closeExtendedFooter() {
            this.showExtendedMenu = false;
        },
        getMenuItemCount(page) {
            const map = {
                [routeName.UPCOMING]: this.eventsCount.upcomingEventsCount,
                [routeName.LIVE]: this.eventsCount.liveEventsCount,
                [routeName.POPULAR]: this.eventsCount.popularEventsCount,
                [routeName.BOOSTED_EVENTS]: this.eventsCount.boostedEventsCount,
            };
            const counts = map[page];
            return { text: getObjectField(counts, String(this.currentCategoryId)) };
        },
    },
};
</script>

<style scoped lang="scss">
.tabs {
    position: relative;

    @include all_but_mini {
        .extended-menu {
            position: absolute;
            top: 0;
            width: 100%;
            background: $navigation-menu-background-color-dark;
            z-index: 10000;
            padding: 8px;
            transition: transform 0.2s linear, opacity 0.2s linear, clip-path 0.2s linear;
            opacity: 0;
            pointer-events: none;
            transform: translateY(-100%);
            clip-path: inset(100% 0 0 0);
            overflow: hidden;

            &.show {
                opacity: 1;
                pointer-events: auto;
                transform: translateY(0);
                clip-path: inset(0 0 0 0);
            }

            .extended-menu-footer {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $navigation-menu-primary-color;
                text-decoration: underline;
                height: 30px;
                padding-top: 8px;

                span {
                    @extend %button-font-500;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
