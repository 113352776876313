<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div v-sniffer="{ handler: onSnifferLoadMore, distance: 0, disabled: isPresto, debounceTimeout: 100 }" class="my-bets">
        <Tabs class="my-bets-tabs" truncate-text :tabs="tabs" :active="currentSelector" @select="setMenu">
            <div v-if="error" class="notify error">
                <renderer :input="error" />
            </div>
            <template v-else>
                <Tab :name="tabType.JACKPOT">
                    <!-- // TODO: replace JackpotBet to BetsList -->
                    <JackpotBet :has-more="!isPreview" @load-more="loadMoreItems(tabType.JACKPOT)" />
                    <ViewAllButton
                        v-if="isPreview && jackpot.items && jackpot.items.length"
                        :section="currentSelector.name"
                        @click.native="viewAllButtonClick(currentSelector.name)"
                    />
                </Tab>
                <Tab :name="tabType.SETTLED">
                    <BetsList
                        :items="settled.items"
                        :is-loading="isItemsLoading(tabType.SETTLED)"
                        :has-more="!isPreview && settled.hasMore"
                        :type="tabType.SETTLED"
                        @load-more="loadMoreItems(tabType.SETTLED)"
                    >
                        <template slot="empty">
                            <EmptyBets :tab-name="tabType.SETTLED" />
                        </template>
                    </BetsList>
                    <ViewAllButton
                        v-if="isPreview && settled.items && settled.items.length"
                        :section="currentSelector.name"
                        @click.native="viewAllButtonClick(currentSelector.name)"
                    />
                </Tab>
                <Tab :name="tabType.PENDING">
                    <BetsList
                        :is-outcome="false"
                        :items="pending.items"
                        :is-loading="isItemsLoading(tabType.PENDING)"
                        :has-more="!isPreview && pending.hasMore"
                        :type="tabType.PENDING"
                        @load-more="loadMoreItems(tabType.PENDING)"
                    >
                        <template #icon="props">
                            <div v-if="isCashOutAvailable && props.bet.hasAutoCashout" class="cashout-container">
                                <span class="cashout-title">{{ $t('ui.cashout.autoCashoutSet') }}</span>
                                <span class="cashout-icon">
                                    <SvgIcon
                                        :title="$t('ui.cashout.autoCashoutSet')"
                                        class="icon-size-small"
                                        icon-id="icon-circle-checked"
                                    />
                                </span>
                            </div>
                            <div
                                v-else-if="isCashOutAvailable && props.bet.cashoutInfo.cashoutable && !props.bet.hasAutoCashOut"
                                class="cashout-container"
                            >
                                <span class="cashout-title">{{ $t('ui.cashout.cashout') }}</span>
                                <span class="cashout-icon">
                                    <SvgIcon :title="$t('ui.cashout.cashout')" class="icon-size-small" icon-id="icon-cashoutable" />
                                </span>
                            </div>
                            <div v-else class="cashout-container">
                                <span class="cashout-title">{{ $t('ui.cashout.noCashout') }}</span>
                                <span class="cashout-icon">
                                    <SvgIcon :title="$t('ui.cashout.noCashout')" class="icon-size-small" icon-id="icon-ban" />
                                </span>
                            </div>
                        </template>
                        <template slot="empty">
                            <EmptyBets :tab-name="tabType.PENDING" />
                        </template>
                    </BetsList>
                    <ViewAllButton
                        v-if="isPreview && pending.items && pending.items.length"
                        :section="currentSelector.name"
                        @click.native="viewAllButtonClick(currentSelector.name)"
                    />
                </Tab>
                <Tab :name="tabType.VIRTUAL">
                    <BetsList
                        :items="virtual.items"
                        :is-loading="isItemsLoading(tabType.VIRTUAL)"
                        :has-more="!isPreview && virtual.hasMore"
                        :type="tabType.VIRTUAL"
                        @load-more="loadMoreItems(tabType.VIRTUAL)"
                    >
                        <template slot="empty">
                            <EmptyBets :tab-name="tabType.VIRTUAL" />
                        </template>
                    </BetsList>
                    <ViewAllButton
                        v-if="isPreview && virtual.items && virtual.items.length"
                        :section="currentSelector.name"
                        @click.native="viewAllButtonClick(currentSelector.name)"
                    />
                </Tab>
                <div v-if="isGetMoreData" class="spinner-wrapper">
                    <Spinner :listen="spinnerTriggers" class="align-top load-more-spinner" />
                </div>
            </template>
        </Tabs>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { myBetsType } from '@/modules/sport';
import { action as sportAction, getter as sportGetter } from '@/modules/sport/store/const';
import { Tab, Tabs } from '@/modules/core/components';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { action, getter as generalGetter } from '@/store/const';

import BetsList from '../BetsList.vue';
import JackpotBet from '../JackpotBet.vue';
import scrollSniffer from '@/js/directives/ScrollSniffer';
import { deviceType } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';
import { getObjectField } from '@/modules/core/utils/helper';
import EmptyBets from '@/modules/sport/components/Fragments/EmptyBets.vue';
import { routeName } from '@/router/const-name';
import ViewAllButton from '@/modules/sport/components/Fragments/Betslip/ViewAllButton.vue';
import { formatNavigationItemCount } from '@/utils/formatNavigationItemCount';
import { getter as platformGetter } from '@/modules/platform/store/const';
const REQUEST_ITEMS_PACE = {
    [myBetsType.PENDING]: 25,
    default: 12,
};

const DEFAULT_PREVIEW_ITEMS_PAGE = 5;

export default {
    name: 'MyBets',
    components: { ViewAllButton, EmptyBets, JackpotBet, BetsList, Tabs, Tab },
    directives: {
        sniffer: scrollSniffer,
    },
    props: {
        isPreview: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tabType: myBetsType,
            currentSelector: {},
            offset: 0,
            offsetStep: 8,
            isRedirectToSettledAvailable: false,
            isPresto: deviceType.isPresto(),
            routeName,
            spinnerTriggers: [
                sportAction.GET_VIRTUAL_BETSLIPS,
                sportAction.GET_SETTLED_BETSLIPS,
                sportAction.GET_JACKPOT_BETSLIPS,
                sportAction.GET_PENDING_BETSLIPS,
            ],
        };
    },
    computed: {
        ...mapState({
            currency: (state) => state.platform.settings.currency,
            error: (state) => state.sport.myBets.error,
            betslipOpen: (state) => state.ui.betslipOpen,
        }),
        ...mapGetters({
            jackpot: sportGetter.GET_JACKPOT_BETS,
            settled: sportGetter.GET_SETTLED_BETS,
            pending: sportGetter.GET_PENDING_BETS,
            virtual: sportGetter.GET_VIRTUAL_BETS,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            myBetsMenu: generalGetter.GET_MY_BETS_MENU,
            isLoading: coreGetter.IS_LOADING,
            pendingBetsCount: sportGetter.GET_PENDING_BETS_COUNT,
            isCashOutAvailable: platformGetter.IS_CASHOUT_AVAILABLE,
        }),
        itemsToTake() {
            if (this.isPreview) return DEFAULT_PREVIEW_ITEMS_PAGE;
            return REQUEST_ITEMS_PACE[this.currentSelector.key] || REQUEST_ITEMS_PACE.default;
        },
        isGetMoreData() {
            return getObjectField(this.mapDataByBetsType[this.currentSelector.key], 'data.items', []).length;
        },
        mapDataByBetsType() {
            const defaultValue = { items: [], hasMore: false };
            return {
                [myBetsType.SETTLED]: {
                    action: sportAction.GET_SETTLED_BETSLIPS,
                    data: getObjectField(this, myBetsType.SETTLED, defaultValue),
                },
                [myBetsType.VIRTUAL]: {
                    action: sportAction.GET_VIRTUAL_BETSLIPS,
                    data: getObjectField(this, myBetsType.VIRTUAL, defaultValue),
                },
                [myBetsType.PENDING]: {
                    action: sportAction.GET_PENDING_BETSLIPS,
                    data: getObjectField(this, myBetsType.PENDING, defaultValue),
                },
                [myBetsType.JACKPOT]: {
                    action: sportAction.GET_JACKPOT_BETSLIPS,
                    data: getObjectField(this, myBetsType.JACKPOT, defaultValue),
                },
            };
        },
        tabs() {
            return this.myBetsMenu.map((item) => {
                if (item.key === this.tabType.PENDING) {
                    return {
                        ...item,
                        count: formatNavigationItemCount(this.pendingBetsCount),
                    };
                }
                return item;
            });
        },
    },
    watch: {
        '$route.params.section': {
            immediate: true,
            handler(section, prevSection) {
                this.isRedirectToSettledAvailable = !prevSection;
                this.setSection(section);
            },
        },
        'pending.items'(pending) {
            if (!pending) {
                return;
            }

            if (!pending.length && this.isRedirectToSettledAvailable) {
                this.$router.push({ ...this.$route, params: { section: myBetsType.SETTLED } });
            }
        },
    },
    methods: {
        isItemsLoading(type) {
            const mapData = this.mapDataByBetsType[type];
            return this.isLoading(mapData.action);
        },
        onSnifferLoadMore() {
            const isValidKey = Object.values(myBetsType).includes(this.currentSelector.key);
            if (!this.isPreview && getObjectField(this.mapDataByBetsType[this.currentSelector.key], 'data.hasMore', false) && isValidKey) {
                this.loadMoreItems(this.currentSelector.key);
            }
        },
        initiateContents() {
            this.$store.dispatch(sportAction.RESET_MY_BETS);
            this.$store.dispatch(this.mapDataByBetsType[this.currentSelector.key].action, { take: this.itemsToTake });
        },
        setMenu(menu) {
            if (this.isPreview) {
                this.setSection(menu.name);
                return;
            }
            if (menu.name === this.currentSelector.name) return;
            this.$router.push({ ...this.$route, params: { section: menu.name } });
        },
        loadMoreItems(type) {
            const mapData = this.mapDataByBetsType[type];
            if (mapData && !this.isLoading(mapData.action)) {
                this.$store.dispatch(mapData.action, {
                    take: this.itemsToTake,
                    skip: mapData.data.items.length,
                });
            }
        },
        generateRequestAgruments() {
            const args = {};
            args.offset = this.offset;
            args.maxResults = this.offsetStep;
            this.offset += this.offsetStep;
            return args;
        },
        setSection(section) {
            let defaultSection, currentSection;
            for (const item of this.myBetsMenu) {
                if (item.name === section) {
                    currentSection = item;
                    break;
                } else if (item.default) {
                    defaultSection = item;
                }
            }
            this.currentSelector = currentSection || defaultSection;
            this.initiateContents();
        },
        viewAllButtonClick(section) {
            this.betslipOpen && this.$store.dispatch(action.TOGGLE_BETSLIP_STATE);
            this.$gtm.query({
                event: 'view_all_button_click',
                section,
                isPreview: this.isPreview,
                isAuthenticated: this.isAuthenticated,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.request-offer {
    align-items: center;
    border: 1px solid $dark-grey;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    line-height: 16px;
    padding: 8px;

    &.error {
        border-color: $error-red;
    }

    &.warn {
        border-color: $golden-brown;
    }

    .offer-message {
        flex: 1;
        font-weight: normal;

        .spinner {
            line-height: initial;
        }
    }

    .offer-amount {
        color: $cashout-offer-amount-color;
        font-weight: 700;
    }

    .offer-button {
        flex: 1;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
    }

    @include only_mini {
        align-items: flex-start;
        flex-direction: column;

        .offer-button {
            margin-top: 10px;
            width: 100%;
        }
    }
}

.load-more-spinner {
    position: static;
    padding: 16px 0;
}

.spinner-wrapper {
    position: relative;
}

.cashout-container {
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background-color: $light-grey;
    border-radius: 4px;

    .cashout {
        &-title {
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            color: $dark-green;
        }

        &-icon {
            @include all_but_mini {
                display: flex;
                align-items: center;
            }
            @include only_mini {
                position: relative;
                bottom: 1px;
                margin-left: 4px;
            }
            width: 14px;
            height: 14px;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
